import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './pref_billing.module.css';
import preferencesStore from '../../../stores/preferences.store';
import PrefCreditCard from './pref_credit_card.component';
import PrefHasCreditCard from './pref_has_credit_card.component';
import environmentService from '../../../services/environment.service';
import { Role } from '../../../models/account.model';
import billingStore from '../../../stores/billing.store';
import memberStore from '../../../stores/member.store';
import download from '../../../img/preferences/download.svg';
import Time from '../../../services/time.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Script: any = require('react-load-script');


interface State {
  name: string;
  error: string;
}

class PrefBilling extends React.Component<{}, State> {
  // childSubmit: (firstName: string, lastName: string, companyName: string, country: string) => void;
  prefCreditCardSubmit?: Function;
  bluesnapUrl: string;
  constructor(props: {}) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.bluesnapLoaded = this.bluesnapLoaded.bind(this);

    this.bluesnapUrl = environmentService.isProduction() ? 'https://ws.bluesnap.com/web-sdk/5/bluesnap.js' : 
    'https://sandbox.bluesnap.com/web-sdk/5/bluesnap.js' ;

  }

  async componentDidMount() {
    await billingStore.loadPlans();
  }
  

  async onClick() {
    this.prefCreditCardSubmit!();
  }

  private bluesnapLoaded() {
    console.log('bluesap Loaded');
    billingStore.setBluesnapLoaded(true);
  }

  render() {
    const account = memberStore.account!;
    const {role}  = preferencesStore;
    const disabled = role === Role.developer;
    const {bluesnapLoaded, plans} = billingStore;
    const hebrew = account.address?.country === 'IL';

    return (
      <div className={styles.component}>
        <Script
          url={this.bluesnapUrl}
          onLoad={this.bluesnapLoaded}
        />        
        {!disabled && bluesnapLoaded &&
          <>
            
            { account.billing?.subscriptionId ?
              <PrefHasCreditCard /> :
              <>
                <PrefCreditCard setHandleSubmit={(handle: Function) => this.prefCreditCardSubmit = handle} /> 
                <button data-bluesnap="submitButton" className={styles.button} onClick={this.onClick}>Submit</button>
              </>
            }
            {!!account.subscription.notification?.failedPayment?.reason && <div className={styles.failedPayment}> <b>Payment failed:</b> <br/>   {account.subscription.notification?.failedPayment?.reason}</div>}
            <hr className={styles.seperator}/>
          </>
        }
        <div className={styles.title}>
          Billing History
        </div>
        <div>
          <table className={styles.history}>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Date</th>
                  <th>Plan</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
              {
                account.payments?.slice().reverse().map((payment, index) => {
                  const planName = plans.find(plan => plan._id === payment.plan)?.name;
                  
                  const url = payment.invoice?.url[hebrew ? 'he' : 'en'];
                  return (
                  <tr key={index}> 
                    <td>{payment.invoice?.number} </td>
                    <td>{new Time(payment.date).getLongDate()} </td>
                    <td> {planName} </td>
                    <td>${payment.amount} </td>
                    <td>{url && <a href={url}><img alt="download" src={download}/></a>}</td>
                  </tr>)
                })
              }
              </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default observer(PrefBilling);
