import React, {useRef, useEffect, useState, useCallback} from 'react';
import { observer } from 'mobx-react';
import appStore from '../../stores/app.store';
import styles from './pref_app_gdpr.module.css';

import plus from '../../img/preferences/plus.svg';
import { Platform } from '../../models/platform.model';
import trash from '../../img/preferences/trash.svg';

const PrefAppGdpr = observer(() => {
  const [udid, setUdid] = useState('');
  const handleSubmitUdid = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedUdid = udid.trim();
    if (trimmedUdid.length <= 5) return;
    appStore.disableDevice(trimmedUdid);
    setUdid('');
  };
  let udidTitle = '';
  const app = appStore.app!;
  switch (app?.platform) {
    case Platform.IOS:
      udidTitle = 'UUID';    
      break;
    case Platform.ANDROID:
      udidTitle = 'Android ID';
      break;
    case Platform.REACT_NATIVE:
    case Platform.FLUTTER:
      udidTitle = 'GUID'; 
      break;
    default:
  }

  return (
    <div className={styles.component}>
      <form onSubmit={handleSubmitUdid} >
        <div className={styles.subTitle}>GDPR</div>
        <div className={styles.bodyHeader}>In accordance with GDPR laws you need to be able to delete and completely remove a user’s information upon their request.*</div>
        <div className={styles.normal}>Add the {udidTitle} that you want to disable and delete here:</div>
        <div className={styles.inputUdid}>
          <input name="udid" type="text" placeholder={udidTitle} value={udid} onChange={(event) => setUdid(event.currentTarget.value) } />
          <button type="submit">
            <img alt="plus" src={plus}/>
          </button>
        </div>
      </form>
      { app.disabledDevices?.length > 0 &&  
        <div>
          <div className={styles.normal}>List of Disabled Devices:</div>
          <div style={{background: 'white'}}>
            { app.disabledDevices.map((elem, index) => {
                return (
                  <div key={index} className={styles.disabledDevice}>
                    <div>{elem}</div>
                    <img alt="trash" src={trash} className={styles.trash} 
                      onClick={() => appStore.enableDevice(elem)} />

                  </div>);
            })}
          </div>
        </div>
      }
      <div className={styles.footnote}>* from the moment a device’s data is removed, all information is completely deleted, past present and future. </div>

    </div>
  );
});

export default PrefAppGdpr;