export enum Platform {
  IOS = 'ios',
  TVOS = 'tvos',
  ANDROID = 'android',
  REACT_NATIVE = 'react-native',
  FLUTTER = 'flutter',
}

export class PlatformUtil {
  static values() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(Platform).map(k => Platform[k as any]);
  }
  static fromString(platform: string) {
    switch (platform) {
      case Platform.ANDROID: return Platform.ANDROID;
      case Platform.IOS: return Platform.IOS;
      case Platform.REACT_NATIVE: return Platform.REACT_NATIVE;
      case Platform.FLUTTER: return Platform.FLUTTER;
      default: return Platform.ANDROID;
    }
  }
}
