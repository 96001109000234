import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from './pref_app_doc.module.css';

import Copy from '../common/copy.component';
import { Platform, PlatformUtil } from '../../models/platform.model';
import trackAnalytic from '../analytics';
import Select from '../common/select.component';

import memberStore from '../../stores/member.store';
import { Integration } from '../../models/app.model';

import success from '../../img/preferences/success.svg';
import lightHouse from '../../img/preferences/lighthouse.svg';
import appleIcon from '../../img/apple-icon.svg';
import androidIcon from '../../img/android-icon.svg';
import reactIcon from '../../img/react-icon.svg';
import flutterIcon from '../../img/flutter-icon.svg';
import xIcon from '../../img/x.svg';
import appStore from '../../stores/app.store';
import PopupScreen from '../common/popup_screen.component';


const CheckIntegration = observer(class CheckIntegration extends React.Component {
  componentDidMount() {
    appStore.startCheckIntegrated();
  }
  componentWillUnmount() {
    appStore.stopCheckIntegrated();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickChat(event: any) {
    event.preventDefault();
    trackAnalytic.openChat();
  }
  render() {
    const app = memberStore.app!;
    switch (app.integration) {
      case Integration.FULL: {
        let url: string;
        switch (app.platform) {
          case Platform.IOS:
            url = 'https://docs.shipbook.io/ios-log-integration';
            break;
          case Platform.ANDROID:
            url = 'https://docs.shipbook.io/android-log-integration';
            break;
          case Platform.REACT_NATIVE:
            url = 'https://docs.shipbook.io/react-native-log-integration';
            break;
          case Platform.FLUTTER:
            url = 'https://docs.shipbook.io/flutter-log-integration';
            break;
          default:
            url = 'https://docs.shipbook.io';
        }
    
        return (
          <div className={styles.check}>
            <img className={styles.checkIcon} src={success} alt="success"/>
            <div className={styles.checkTextBox}>
              <div className={styles.checkSuccessTitle1}> Success! Your app is integrated. </div> 
              <div className={styles.checkSuccessTitle2}>Now set sail &amp; start fixing!</div>
              <div className={styles.checkSuccessBody1}>For more information:</div>
              <div className={styles.checkSuccessBody2}>View our detailed integration document <a href="route" 
                onClick={(event) => { 
                  event.preventDefault(); 
                  window.open(url);
                }}>here</a>
              </div> 
            </div>
          </div>
        );
      }
      case Integration.SESSION:
        return (
          <div className={styles.check}>
            <img className={styles.checkIcon} src={lightHouse} alt="waiting"/>
            <div className={styles.checkTextBox}>
              <div className={styles.checkSessionTitle}>The app successfully communicated with the server. <br/> But it looks like you still haven’t uploaded any logs yet. </div>
              <div className={styles.checkSessionBody}>The system needs your logs before you can start utilizing Shipbook’s vast logging capabilities. </div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <div className={styles.checkProblem}> If the problem persists, please <a onClick={this.onClickChat}>chat with us</a></div>
            </div>     
          </div>
        );
      case Integration.NONE:
      default:
        return (
          <div className={styles.check}>
            <img className={styles.checkIcon} src={lightHouse} alt="waiting"/>
            <div className={styles.checkTextBox}>
              <div className={styles.checkWaitingText}>Checking if the app has<br/> communicated with our servers...</div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <div className={styles.checkProblem}> If the problem persists, please <a onClick={this.onClickChat}>chat with us</a></div>
            </div>
          </div>
        );   
    }
  }
});

interface CopyBoxProps {
  text: string;
  copy?: string;
  trackText?: string;
  language: string;
}
class CopyBox extends React.Component<CopyBoxProps> {
  constructor(props: CopyBoxProps) {
    super(props);
    this.onCopy = this.onCopy.bind(this);    
  }

  private onCopy() {
    const trackText = this.props.trackText ? this.props.trackText : this.props.text;
    trackAnalytic.event('integration', 'copy', trackText);
  }

  render() {
    return (
      <div className={styles.copyBox}>
        <SyntaxHighlighter language={this.props.language} style={docco}>{this.props.text}</SyntaxHighlighter>
        <div>
          <Copy text={this.props.copy ? this.props.copy : this.props.text} onCopy={this.onCopy} />
        </div>
      </div>    
    );
  }
}

interface DocRowProps {
  stage: number;
  currentStage: number;
  title: string;
  setStage: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  last?: any;
}
class DocRow extends React.Component<DocRowProps> {
  buttonsRef: React.RefObject< HTMLDivElement >;
  constructor(props: DocRowProps) {
    super(props);
    this.buttonsRef = React.createRef();
  }

  componentDidUpdate(prevProps: DocRowProps) {
    if (prevProps.currentStage !== this.props.currentStage && this.props.currentStage === this.props.stage) {
      this.buttonsRef.current!.scrollIntoView({behavior: 'smooth', block: 'end'});
    }
  }

  nextStage() {
    const stage = this.props.stage + 1;
    this.props.setStage(stage);
    trackAnalytic.event('integration', 'stage', 'stage ' + stage);
  }

  previousStage() {
    const stage = this.props.stage - 1;
    this.props.setStage(stage);
    trackAnalytic.event('integration', 'stage', 'stage ' + stage);
  }

  thisStage() {
    this.props.setStage(this.props.stage);
    trackAnalytic.event('integration', 'stage', 'stage ' + this.props.stage);
  }

  render() {
    const {stage, currentStage} = this.props;
    let stageClass: string;
    if (stage < currentStage) stageClass = styles.stagePrevious;
    else if (stage === currentStage) stageClass = styles.stageCurrent;
    else stageClass = styles.stageNext;

    return (
      <>
        <div className={styles.docRowNumber}>
          <div className={stageClass} onClick={() => this.props.setStage(stage)}>
            {stage}
          </div>
          {!this.props.last && <div className={styles.verticalLine}/>}
        </div>
        <div>
          <div className={stage === currentStage ? styles.docTitleSelected : styles.docTitle} 
            onClick={() => this.props.setStage(stage)}>
            {this.props.title}
          </div>
          {this.props.stage === this.props.currentStage &&
            <>
              {this.props.children}
              <div className={styles.buttonWrapper} ref={this.buttonsRef}>
                {stage > 1 && 
                  <button className={styles.previous} onClick={() => this.props.setStage(stage - 1)}>Previous</button>}
                {!this.props.last ?
                  <button className={styles.next} onClick={() => this.props.setStage(stage + 1)}>Next</button> :
                  this.props.last !== true && <button className={styles.next} onClick={this.props.last}>Done</button>}
              </div>
            </>
          }
        </div>
      </>
    );
  }
}

interface DocState {
  stage: number;
}

abstract class Doc extends React.Component<RouteComponentProps<{}>, DocState> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {stage: 1};
    this.setStage = this.setStage.bind(this);
    this.last = this.last.bind(this);
  }

  protected setStage(stage: number) {
    trackAnalytic.event('integration', 'stage', 'stage ' + stage);
    this.setState({stage});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected last(event: any) {
    event.preventDefault();
    const {accountId, appId} = memberStore;
    trackAnalytic.event('integration', 'stage', 'done');
    this.props.history.push(`/${accountId}/${appId}/logbook`);
  }
}

class IOsDocClass extends Doc {
  render() {
    const language = 'swift';
    const {stage} = this.state;
    const {app} = appStore;
    if (!app) return null;
    return (
      <div className={styles.doc}>
        <DocRow 
          stage={1} 
          currentStage={stage} 
          title="Installation via Swift Package Manager"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            ShipBookSDK uses <a href="https://swift.org/package-manager/">Swift Package Manager</a> to install and manage dependencies. 
          </div>
          <div className={styles.docBody}>
            In Xcode: Select File &gt; Swift Packages &gt; Add Package Dependency and enter
          </div>
          <CopyBox language="text" text="https://github.com/ShipBook/ShipBookSDK-iOS.git"/>
          <div className={styles.docBodyRemark}>
            For other ways to integrate go to <a href="https://www.shipbook.io/ios-doc">iOS Doc</a>
          </div>
        </DocRow>
        <DocRow 
          stage={2} 
          currentStage={stage} 
          title="Initializing ShipBook into your code"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            In your <span>AppDelegate</span> file, add the following:
          </div>
          <CopyBox language={language} text="import ShipBookSDK"/>
          <div className={styles.docBody}>
            Then, add the following to <span>application(_:didFinishLaunchingWithOptions:)</span>:
          </div>
          <CopyBox language={language} 
            text={`ShipBook.start(appId:"${app._id}",
               appKey:"${app.key}")`}
            copy={`ShipBook.start(appId:"${app._id}", appKey:"${app.key}")`}
            trackText="ShipBook.start"/>
        </DocRow>
        <DocRow
          stage={3}
          currentStage={stage}
          title="Adding logs"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            The usage of the logs:
          </div>
          <CopyBox language={language} text={`Log.e("the log message") // Error log
Log.w("the log message") // Warning log
Log.i("the log message") // Info log
Log.d("the log message") // Debug log
Log.v("the log message") // Verbose log`}
          trackText="Log.e ..."/>
        </DocRow>
        <DocRow
          stage={4}
          currentStage={stage}
          title="Run your app to verify installation."
          setStage={this.setStage}
          last={app.integration === Integration.FULL ? this.last : true}>
          <CheckIntegration/>
        </DocRow>
    </div>
    );
  }
}
const IOsDoc = withRouter(observer(IOsDocClass));

class ObjcDocClass extends Doc {
  render() {
    const language = 'objc';
    const {stage} = this.state;
    const {app} = appStore;
    if (!app) return null;
    return (
      <div className={styles.doc}>
        <DocRow 
          stage={1} 
          currentStage={stage} 
          title="Installation via Swift Package Manager"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            ShipBookSDK uses <a href="https://swift.org/package-manager/">Swift Package Manager</a> to install and manage dependencies. 
          </div>
          <div className={styles.docBody}>
            In Xcode: Select File &gt; Swift Packages &gt; Add Package Dependency and enter
          </div>
          <CopyBox language="text" text="https://github.com/ShipBook/ShipBookSDK-iOS.git"/>
          <div className={styles.docBodyRemark}>
            For other ways to integrate go to <a href="https://www.shipbook.io/ios-doc">iOS Doc</a>
          </div>
        </DocRow>
        <DocRow
          stage={2}
          currentStage={stage}
          title="Adapting app to except Swift"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            Create an empty swift file. (file-&gt;new-&gt;file-&gt;swift file)
            <br/><br/>
            A popup window will show with the following title: <span>Would you like to configure an 
              Objective-C bridging header?</span><br/><br/>
            Press <span>"Create Bridging Header"</span>
          </div>
        </DocRow>
        <DocRow 
          stage={3} 
          currentStage={stage} 
          title="Initializing ShipBook into your code"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            In your <span>AppDelegate</span> file, add the following:
          </div>
          <CopyBox language={language} text="@import ShipBookSDK;"/>
          <div className={styles.docBody}>
            Then, add the following to <span>- (BOOL)application:(UIApplication *)application 
              didFinishLaunchingWithOptions:(NSDictionary *)launchOptions </span>:
          </div>
          <CopyBox language={language} 
            text={`[ShipBook startWithAppId:@"${app._id}" 
                  appKey:@"${app.key}"];`}
            copy={`[ShipBook startWithAppId:@"${app._id}" appKey:@"${app.key}"];`}
            trackText="ShipBook.start"/>
        </DocRow>
        <DocRow
          stage={4}
          currentStage={stage}
          title="Adding logs"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            To have a log on each class you need to create a logger. <br/>
            For Example, in MainViewController:
          </div>

          <CopyBox language={language} text="@import ShipBookSDK;"/>

          <div className={styles.docBody}>
            The usage of the log:
          </div>
          <CopyBox language={language} text={`LogE(@"the log message"); // Error log
LogW(@"the log message"); // Warning log
LogI(@"the log message"); // Info log
LogD(@"the log message"); // Debug log
LogV(@"the log message"); // Verbose log`}
          trackText="log.e ..."/>
        </DocRow>
        <DocRow
          stage={5}
          currentStage={stage}
          title="Run your app to verify installation."
          setStage={this.setStage}
          last={app.integration === Integration.FULL ? this.last : true}>
          <CheckIntegration />
        </DocRow>
    </div>
    );
  }
}
const ObjcDoc = withRouter(observer(ObjcDocClass));

class AndroidDocClass extends Doc {
  render() {
    const language = 'java';
    const {stage} = this.state;
    const {app} = appStore;
    if (!app) return null;
    return (
      <div className={styles.doc}>
        <DocRow 
          stage={1} 
          currentStage={stage} 
          title="Installation"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            ShipBookSDK is available through mavenCentral. 
            To install it, simply add the following line to the dependencies in your <span>build.gradle</span>: 
          </div>
          <CopyBox language={language} text="implementation 'io.shipbook:shipbooksdk:1.+'"/>
        </DocRow>
        <DocRow 
          stage={2} 
          currentStage={stage} 
          title="Integrating Shipbook into your code"
          setStage={this.setStage}>
          <div className={styles.docBody}>Add the following to your <span>application</span> file:</div>
          <CopyBox language={language} text="import io.shipbook.shipbooksdk.ShipBook;"/>
          <div className={styles.docBody}>
            And add the following to <span>onCreate()</span>:
          </div>
          <CopyBox language={language} 
            text={`ShipBook.start(this, "${app._id}", 
                     "${app.key}");`}
            copy={`ShipBook.start(this, "${app._id}", "${app.key}");`}/>
        </DocRow>
        <DocRow 
          stage={3} 
          currentStage={stage} 
          title="Quick Implementation"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            You can call all the usual Android logs, the only difference is that you should change the import from 
            <span> import android.util.Log;</span> to <span>import io.shipbook.shipbooksdk.Log;</span>. <br/>
            <CopyBox language={language} text={`import io.shipbook.shipbooksdk.Log;`}/>
            For example:
          </div>

          <CopyBox language={language} text={`Log.e(TAG, "the log message"); // Error log
Log.w(TAG, "the log message"); // Warning log
Log.i(TAG, "the log message"); // Info log
Log.d(TAG, "the log message"); // Debug log
Log.v(TAG, "the log message"); // Verbose log
`}/>
        </DocRow>
        <DocRow 
          stage={4} 
          currentStage={stage} 
          title="Run your app to verify installation."
          setStage={this.setStage}
          last={app.integration === Integration.FULL ? this.last : true}>
          <CheckIntegration />
        </DocRow>
      </div>
    );
  }
}
const AndroidDoc = withRouter(observer(AndroidDocClass));

class ReactNativeDocClass extends Doc {
  render() {
    const language = 'java';
    const {stage} = this.state;
    const {app} = appStore;
    if (!app) return null;
    return (
      <div className={styles.doc}>
        <DocRow 
          stage={1} 
          currentStage={stage} 
          title="Installation with NPM"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            Shipbook is available through: NPM
          </div>
          <CopyBox language={language} text="npm i @shipbook/react-native"/>
        </DocRow>
        <DocRow 
          stage={2} 
          currentStage={stage} 
          title="Initializing ShipBook into your code"
          setStage={this.setStage}>
          <div className={styles.docBody}>To initialize shipbook, add the following:</div>
          <CopyBox language={language} text={`import shipbook from '@shipbook/react-native';

shipbook.start('${app._id}',
               '${app.key}');`}/>
        </DocRow>
        <DocRow 
          stage={3} 
          currentStage={stage} 
          title="Adding logs"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            To have a log on each class you need to create a logger:
          </div>

          <CopyBox language={language} text={`import shipbook from '@shipbook/react-native';
  
let log = shipbook.getLogger("MODULE_NAME"); 
`}/>

          <div className={styles.docBody}>
            The usage of the logs:
          </div>

          <CopyBox language={language} text={`log.e("the log message"); // Error log
log.w("the log message"); // Warning log
log.i("the log message"); // Info log
log.d("the log message"); // Debug log
log.v("the log message"); // Verbose log
`}/>
        </DocRow>
        <DocRow 
          stage={4} 
          currentStage={stage} 
          title="Run your app to verify installation."
          setStage={this.setStage}
          last={app.integration === Integration.FULL ? this.last : true}>
          <CheckIntegration />
        </DocRow>
      </div>
    );
  }
}
const ReactNativeDoc = withRouter(observer(ReactNativeDocClass));
interface State {
  platform: Platform;
  language: string;
}

class FlutterDocClass extends Doc {
  render() {
    const language = 'dart';
    const {stage} = this.state;
    const {app} = appStore;
    if (!app) return null;
    return (
      <div className={styles.doc}>
        <DocRow 
          stage={1} 
          currentStage={stage} 
          title="Installation with pub.dev"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            ShipBookSDK is available as a package on pub.dev.
          </div>
          <CopyBox language={language} text="flutter pub add shipbook_flutter"/>
        </DocRow>
        <DocRow 
          stage={2} 
          currentStage={stage} 
          title="Initializing ShipBook into your code"
          setStage={this.setStage}>
          <div className={styles.docBody}>To initialize shipbook, add the following:</div>
          <CopyBox language={language} text={`import 'package:shipbook_flutter/shipbook_flutter.dart';

Shipbook.start('${app._id}',
               '${app.key}');
`}/>
        </DocRow>
        <DocRow 
          stage={3} 
          currentStage={stage} 
          title="Adding logs"
          setStage={this.setStage}>
          <div className={styles.docBody}>
            To have a log on each class you need to create a logger:
          </div>

          <CopyBox language={language} text={`import shipbook from '@shipbook/react-native';
  
final log = Shipbook.getLogger('MODULE_NAME');
`}/>
          <div className={styles.docBody}>
            The usage of the logs:
          </div>
          <CopyBox language={language} text={`log.e('the log message'); // Error log
log.w('the log message'); // Warning log
log.i('the log message'); // Info log
log.d('the log message'); // Debug log
log.v('the log message'); // Verbose log
`}/>
        </DocRow>
        <DocRow 
          stage={4} 
          currentStage={stage} 
          title="Run your app to verify installation."
          setStage={this.setStage}
          last={app.integration === Integration.FULL ? this.last : true}>
          <CheckIntegration />
        </DocRow>
      </div>
    );
  }
}

const FlutterDoc = withRouter(observer(FlutterDocClass));

class PrefAppDoc extends React.Component<RouteComponentProps<{}>, State> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const platform = PlatformUtil.fromString(urlParams.get('platform') || '');
    this.state = {platform, language: 'swift'};
    this.onChange = this.onChange.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  onChange(language: string) {
    console.log('the language is:', language);
    this.setState({language});
  }

  onExit() {
    this.props.history.goBack();
  }

  render() {
    let img: string;
    let doc;
    switch (this.state.platform) {
      case Platform.IOS:
        img = appleIcon;
        doc = this.state.language === 'swift' ? <IOsDoc/> : <ObjcDoc/>;
        break;
      case Platform.ANDROID:
        img = androidIcon;
        doc = <AndroidDoc/>;
        break;
      case Platform.REACT_NATIVE:
        img = reactIcon;
        doc = <ReactNativeDoc/>;
        break;
      case Platform.FLUTTER:
        img = flutterIcon;
        doc = <FlutterDoc/>;
        break;
      default: // should never happen
        img = androidIcon;
        doc = <AndroidDoc/>;
    }

    return (
      <PopupScreen title='App Integration' titleCenter={
        <>
          {this.state.platform === Platform.IOS  &&
            <Select value={this.state.language}
              nameValues={{swift: 'Swift', 'objective-c': 'Objective-C'}} 
              onChange={this.onChange}/>  
          }
          <img className={styles.titleImg} src={img} alt={this.state.platform}/>
        </>
      } onExit={this.onExit}>
        <div className={styles.body}>
          {doc}
        </div>
      </PopupScreen>
    );
  }
}
export default withRouter(PrefAppDoc);
